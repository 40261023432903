import React from 'react'
import { Button } from '@material-ui/core'
import {
  CustomModalBody,
  CustomModalFooter
} from 'global/globalComponents/CustomModal'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Skeleton } from '@material-ui/lab'
import CategoryCard from '../CategoryCard/CategoryCard'

const CategoriesScreen = ({ handleSetRequestTemplate, handleClose }) => {
  const { t } = useTranslation()
  const serviceCategories = useSelector(
    state => state.userServices.serviceCategories
  )
  const requestTemplates = useSelector(
    state => state.clientRequests.formTemplates
  )

  const handleOnCategoryClick = categoryId => {
    const template = requestTemplates.data.find(
      item => item.category === categoryId
    )

    handleSetRequestTemplate(template)
  }

  return (
    <>
      <CustomModalBody style={{ height: 500 }}>
        <div className="mb-12">
          <p className="mt-2 mb-4 font-medium text-sm">
            {t('SELECT_REQUEST_CATEGORY_MESSAGE')}:
          </p>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
              gridGap: '1rem'
            }}
          >
            {serviceCategories.loading ? (
              <LoadingAnimation />
            ) : (
              <>
                {serviceCategories.data.length === 0 && (
                  <CategoryCard
                    name={t('DEFAULT')}
                    description={`${t('DEFAULT')} ${t('CATEGORY')}`}
                    onClick={() => handleOnCategoryClick(null)}
                  />
                )}

                {serviceCategories.data.map(item => (
                  <CategoryCard
                    t={t}
                    {...item}
                    onClick={() => handleOnCategoryClick(item._id)}
                  />
                ))}
              </>
            )}
          </div>
        </div>
      </CustomModalBody>

      <CustomModalFooter>
        <Button
          size="large"
          type="button"
          variant="outlined"
          onClick={handleClose}
        >
          {t('CLOSE')}
        </Button>
      </CustomModalFooter>
    </>
  )
}

const LoadingAnimation = () => {
  return (
    <>
      {new Array(3).fill('').map(item => (
        <Skeleton variant="rect" height={200} className="rounded-md" />
      ))}
    </>
  )
}

export default CategoriesScreen
