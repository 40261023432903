import { useState, useEffect, useRef, useMemo } from 'react'
import {
  Button,
  CircularProgress,
  List,
  ListItem,
  makeStyles,
  Popover,
  TextField,
  useTheme
} from '@material-ui/core'
import {
  CustomModal,
  CustomModalHeader,
  CustomModalBody,
  CustomModalFooter
} from 'global/globalComponents/CustomModal'
import PriorityDropDown, {
  getPriorityLabels
} from 'global/globalComponents/PriorityDropdown/PriorityDropdown'
import {
  LoadingButton,
  FormLabel,
  CustomFlag,
  CustomTooltip,
  FilePreview
} from 'global/globalComponents'
import { useDispatch, useSelector } from 'react-redux'
import {
  fireErrorToaster,
  fireSuccessToaster,
  fireWarningToaster
} from 'thunks/fireToaster/actions'
// import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import Select from 'react-select'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'
import { ReactComponent as SlidersHorizontalIcon } from 'static/svg/sliders-horiz.svg'
// import { ReactComponent as Add } from 'static/svg/plus.svg'
import { ReactComponent as FlagOutlined } from 'static/svg/pennant-outlined.svg'
// import { ReactComponent as LinkIcon } from 'static/svg/link.svg'
// import { ReactComponent as NoImage } from 'static/svg/no-image.svg'
// import { ReactComponent as PaperClipIcon } from 'static/svg/paperclip.svg'
// import { ReactComponent as FileIcon } from 'static/svg/file.svg'
import { ReactComponent as ChevronRight } from 'static/svg/chevron-right.svg'
import Pill, { pillVariantObj } from 'global/globalComponents/Pill/Pill'
import { useTranslation } from 'react-i18next'
import { getErrorMessages } from 'utils/errorMessages'
import DatePicker from 'react-datepicker'
import { ReactComponent as CalenderIcon } from 'static/svg/calendar.svg'
import {
  getFileExtFromBaseUrl,
  getFileType,
  // getFileUrl,
  // isUrl,
  userRoles
} from 'utils'
import { getUserRootFolder } from 'thunks/fileAssets/actions'
import { updateFeeds } from 'thunks/feeds/action'
import {
  createClientRequest,
  updateClientRequest,
  uploadAttachment
  // deleteAttachment
} from 'thunks/clientRequests/actions'
import RequestFormTemplateWrapper from './RequestFormTemplateWrapper'
import CategoriesScreen from './CategoriesScreen'
import URLField from './URLField'
import AttachmentField from './AttachmentField'

const RaiseTicketModal = ({ open, handleClose, data, ...rest }) => {
  const [currentStep, setCurrentStep] = useState(0)
  const [requestTemplate, setRequestTemplate] = useState(null)
  const { t } = useTranslation()
  const requestTemplates = useSelector(
    state => state.clientRequests.formTemplates
  )

  useEffect(() => {
    if (!open) {
      setTimeout(() => {
        setCurrentStep(0)
      }, 300)
    }
  }, [open])

  useEffect(() => {
    if (data?._id) {
      const template = requestTemplates.data.find(
        item => item._id === data?.clientRequestTemplate?.templateId
      )

      handleSetRequestTemplate(template)
    }
  }, [data])

  const handleSetRequestTemplate = template => {
    setRequestTemplate(template)
    setCurrentStep(1)
  }

  return (
    <CustomModal open={open} handleClose={handleClose} size="extraLarge">
      <CustomModalHeader
        heading={Boolean(data) ? t('YOUR_REQUEST') : t('CREATE_REQUEST')}
        handleClose={handleClose}
        icon={
          <span className="w-10 h-10 bg-primary-light rounded-lg flex justify-center items-center text-xl text-primary-main dark:text-dark-light dark:bg-dark-main">
            <SlidersHorizontalIcon />
          </span>
        }
      />

      {currentStep === 0 ? (
        <CategoriesScreen
          data={data}
          handleSetRequestTemplate={handleSetRequestTemplate}
          handleClose={handleClose}
        />
      ) : (
        <RaiseTicketModalContent
          handleClose={handleClose}
          requestTemplate={requestTemplate}
          data={data}
          handleGoBack={() => setCurrentStep(0)}
          {...rest}
        />
      )}
    </CustomModal>
  )
}

const RaiseTicketModalContent = ({
  handleClose,
  data,
  canUpdate,
  elmFor = 'client',
  handleGoBack,
  requestTemplate
}) => {
  const formTemplateRef = useRef()
  const { t } = useTranslation()
  const errorMessages = getErrorMessages()
  const meData = useSelector(state => state.me.data)
  const [anchorEl, setAnchorEl] = useState('')
  const [statusAnchor, setStatusAnchor] = useState('')
  const [loading, setLoading] = useState(false)
  const [client, setClient] = useState({})
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    attachments: [],
    priority: {},
    status: 'pending',
    dueDate: '',
    urls: []
  })
  const dispatch = useDispatch()
  const classes = useStyles()
  const priorityLabels = getPriorityLabels()
  const [rootDir, setRootDir] = useState({})
  // const [loadingAttachment, setLoadingAttachment] = useState(false)
  const [filePreviewModal, setFilePreviewModal] = useState({
    open: false,
    files: [],
    fileIndex: 0
  })

  useEffect(() => {
    if (!data) return

    setFormData({
      ...data,
      priority: data.priority
        ? priorityLabels.find(item => item.order === data.priority)
        : {}
    })
  }, [data])

  // useEffect(() => {
  //   if (elmFor === 'agency') {
  //     setClientOptions(
  // userClients
  //   .filter(user => user.isActive)
  //         .map(user => ({ ...user, label: user.name, value: user._id }))
  //     )
  //   } else {
  //     setClientOptions([])
  //   }
  // }, [userClients, elmFor])

  useEffect(() => {
    let userId = null

    if (elmFor === 'agency') userId = client._id
    else userId = meData._id

    if (!userId) return

    // fetching root folder
    dispatch(
      getUserRootFolder({ userId }, (res, err) => {
        if (!err) {
          setRootDir(res)
        }
      })
    )
  }, [meData._id, client])

  const handlePopperClose = () => {
    setAnchorEl('')
  }

  const handlePriorityChange = item => {
    handlePopperClose()
    setFormData(prev => ({ ...prev, priority: item }))
  }

  const handleCreateRequest = async () => {
    if (!canUpdate) {
      dispatch(
        fireErrorToaster(t('NOT_HAVE_PERMISSION_TO_PERFORM_THIS_ACTION'))
      )
      return
    }

    if (!formData.title.trim()) {
      dispatch(fireErrorToaster(t('ERROR_PLEASE_FILL_THE_REQUIRED_FIELDS')))
      return
    }

    let body = {
      title: formData.title,
      priority: formData.priority.order,
      description: formData.description,
      attachments: formData.attachments,
      status: formData.status,
      dueDate: formData.dueDate,
      urls: formData.urls
    }

    if (elmFor === 'agency') {
      if (!client._id) {
        dispatch(fireErrorToaster(t('ERROR_PLEASE_SELECT_THE_CLIENT')))
        return
      }

      body.client = client._id
      body.agencyRepresentative = client.agencyRepresentative || null
    } else {
      if (meData.agencyRepresentative) {
        body.assignAgency =
          meData.agencyRepresentative._id ?? meData.agencyRepresentative
      }
    }

    setLoading(true)

    // ========== For the dynamic template =========
    if (requestTemplate) {
      const fields = formTemplateRef.current.getFieldsData()
      const invalidField = fields.find(
        field => field.required && field.value.trim() === ''
      )

      if (invalidField) {
        setLoading(false)
        dispatch(fireErrorToaster(t('ERROR_PLEASE_FILL_THE_REQUIRED_FIELDS')))
        return
      }

      body.clientRequestTemplate = {
        templateId: requestTemplate._id,
        data: fields
      }

      const uploadPromises = body.clientRequestTemplate.data.map(
        async field => {
          const fieldObj = { ...field, contentId: field._id }

          if (fieldObj.datatype === 'file' && fieldObj.value instanceof File) {
            const file = fieldObj.value

            if (!rootDir._id) {
              dispatch(fireWarningToaster(t('FILE_UPLOAD_FAILED_MESSAGE')))
              fieldObj.value = ''
              return fieldObj
            }

            try {
              fieldObj.value = await handleUploadFormAttachments({
                file,
                rootDirId: rootDir._id
              })
            } catch (err) {
              dispatch(fireWarningToaster(t('FILE_UPLOAD_FAILED_MESSAGE')))
              fieldObj.value = ''
            }
            return fieldObj
          }
          return fieldObj
        }
      )

      body.clientRequestTemplate.data = await Promise.all(uploadPromises)
    } else {
      // ========== For default template =========
      const uploadPromises = body.attachments
        .map(async attachment => {
          if (attachment instanceof File) {
            if (!rootDir._id) {
              dispatch(fireWarningToaster(t('FILE_UPLOAD_FAILED_MESSAGE')))
              return '' //return empty string
            }

            try {
              return await handleUploadFormAttachments({
                file: attachment,
                rootDirId: rootDir._id
              })
            } catch (err) {
              dispatch(fireWarningToaster(t('FILE_UPLOAD_FAILED_MESSAGE')))
              return ''
            }
          }

          return attachment
        })
        .filter(item => Boolean(item))

      body.attachments = await Promise.all(uploadPromises)
    }

    if (data) {
      dispatch(
        updateClientRequest({ id: data._id, body }, raiseRequestCallback)
      )
    } else {
      dispatch(
        createClientRequest(
          {
            body: {
              ...body,
              status: 'pending'
            },
            elmFor
          },
          raiseRequestCallback
        )
      )
    }
  }

  const handleUploadFormAttachments = async ({ file, rootDirId }) => {
    try {
      const response = await uploadAttachment({
        data: {
          fileName: file.name.slice(0, file.name.lastIndexOf('.')),
          file: file,
          fileAssetsFolder: rootDirId
        },
        metadata: {
          fileAssetsFolder: rootDirId
        }
      })
      return response.url
    } catch (error) {
      throw new Error(error)
    }
  }

  const raiseRequestCallback = (res, err) => {
    setLoading(false)
    if (err) {
      dispatch(fireErrorToaster(res))
    } else {
      dispatch(fireSuccessToaster(t('REQUEST_CREATED_SUCCESSFULLY')))
      if (meData.role === userRoles.USER_CLIENT) {
        let newFeed = {
          ...res,
          user: {
            _id: meData._id,
            name: meData.name,
            profileImage: meData.profileImage,
            role: meData.role,
            email: meData.email
          }
        }
        dispatch(updateFeeds(newFeed))
      }
      handleClose()
    }
  }

  const handleInputChange = e => {
    setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }))
  }

  const closeStatusPopup = () => {
    setStatusAnchor(null)
  }

  const handleStatusChange = status => {
    setFormData(prev => ({ ...prev, status }))
    closeStatusPopup()
  }

  const handleOptionsBtnClick = e => {
    if (elmFor === 'client') return
    if (canUpdate) {
      setStatusAnchor(e.currentTarget)
    } else {
      dispatch(fireErrorToaster(errorMessages.NOT_AUTHORIZED))
    }
  }

  const handleDateChange = e => {
    setFormData(prev => ({ ...prev, dueDate: e?.toISOString() }))
  }

  const handleAddUrl = urlObj => {
    setFormData(prev => ({
      ...prev,
      urls: [...prev.urls, urlObj]
    }))
  }

  const handleRemoveUrl = url => {
    setFormData(prev => ({
      ...prev,
      urls: prev.urls.filter(item => item.url !== url)
    }))
  }

  const handleAddAttachment = attachment => {
    setFormData(prev => ({
      ...prev,
      attachments: [...prev.attachments, attachment]
    }))
  }

  const handleRemoveAttachment = attachmentIndex => {
    setFormData(prev => ({
      ...prev,
      attachments: prev.attachments.filter(
        (item, index) => index !== attachmentIndex
      )
    }))
  }

  const handleDynAttachmentPreview = files => {
    if (filePreviewModal.open) {
      setFilePreviewModal(prev => ({ ...prev, open: false }))
    } else {
      let fileObjects = files.map(fileUrl => {
        const file = {}
        const arr = fileUrl.split('/')
        file.name = arr[arr.length - 1]
        file.type = getFileType(getFileExtFromBaseUrl(fileUrl))
        file.url = fileUrl
        file.extension = fileUrl.slice(fileUrl.lastIndexOf('.'))
        return file
      })
      // }
      if (files.length)
        setFilePreviewModal({ open: true, files: fileObjects, fileIndex: 0 })
    }
  }

  return (
    <>
      <CustomModalBody style={{ height: 500 }}>
        <div className="py-4 mb-12">
          {elmFor === 'agency' && (
            <ClientSelector t={t} client={client} setClient={setClient} />
          )}

          <div className="mb-3">
            <FormLabel htmlFor="name" required>
              {t('TITLE')}
            </FormLabel>

            <TextField
              name="title"
              value={formData.title}
              onChange={handleInputChange}
              placeholder={t('TITLE')}
              disabled={!canUpdate}
              style={{ width: '100%' }}
              inputProps={{
                style: { paddingTop: '12px', paddingBottom: '12px' }
              }}
            />
          </div>

          {!requestTemplate ? (
            <>
              <div className="mb-3">
                <FormLabel htmlFor="name" required>
                  {t('DESCRIPTION')}
                </FormLabel>
                <div>
                  <TextField
                    name="description"
                    value={formData.description}
                    onChange={handleInputChange}
                    placeholder={t('WRITE_DESCRIPTION')}
                    multiline={true}
                    rows={4}
                    rowsMax={8}
                    fullWidth
                    disabled={!canUpdate}
                  />
                </div>
              </div>

              {/* ======== Add URLS ========= */}
              <URLField
                canUpdate={canUpdate}
                formData={formData}
                handleAddUrl={handleAddUrl}
                handleRemoveUrl={handleRemoveUrl}
              />

              <AttachmentField
                attachments={formData.attachments}
                handleAddAttachment={handleAddAttachment}
                handleRemoveAttachment={handleRemoveAttachment}
              />
            </>
          ) : (
            <RequestFormTemplateWrapper
              ref={formTemplateRef}
              templateFields={
                data ? data.clientRequestTemplate.data : requestTemplate.items
              }
            />
          )}

          {/* =========== Required Fields ======== */}
          <hr
            style={{ border: 'none', borderTop: '2px dashed #ddd' }}
            className="mt-8 mb-3"
          />

          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              gridGap: '0.5rem'
            }}
          >
            {/* ========== Due Date ========== */}
            <DueDateField
              handleDateChange={handleDateChange}
              formData={formData}
              classes={classes}
            />

            {/* ========== Priority ========== */}
            <PriorityField formData={formData} setAnchorEl={setAnchorEl} />

            {/* ========= Status ========== */}
            <StatusField
              formData={formData}
              handleOptionsBtnClick={handleOptionsBtnClick}
            />
          </div>
        </div>
      </CustomModalBody>

      <CustomModalFooter>
        <Button
          size="large"
          type="button"
          variant="outlined"
          onClick={data ? handleClose : handleGoBack}
          startIcon={!data && <ChevronRight className="rotate-180 w-4 h-4" />}
        >
          {data ? t('CANCEL') : t('BACK')}
        </Button>
        <LoadingButton
          size="large"
          type="submit"
          onClick={handleCreateRequest}
          loading={loading}
        >
          {data ? t('UPDATE') : t('SUBMIT')}
        </LoadingButton>
      </CustomModalFooter>
      <FilePreview
        open={filePreviewModal.open}
        files={filePreviewModal.files}
        fileIndex={filePreviewModal.fileIndex}
        onClose={handleDynAttachmentPreview}
      />
      <PriorityDropDown
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handlePopperClose}
        onChange={handlePriorityChange}
        currentPriority={formData.priority.order}
      />

      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        open={Boolean(statusAnchor)}
        onClose={closeStatusPopup}
        anchorEl={statusAnchor}
      >
        <List dense>
          {[
            { label: t('PENDING'), value: 'pending' },
            { label: t('COMPLETED'), value: 'resolved' },
            { label: t('CLOSED'), value: 'closed' }
          ].map(item => (
            <ListItem
              onClick={() => handleStatusChange(item.value)}
              button
              key={item.value}
              className="capitalize text-sm font-medium"
              selected={formData.status === item.value}
            >
              <Pill variant={pillVariantObj[item.value]}>{item.label}</Pill>
            </ListItem>
          ))}
        </List>
      </Popover>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  pseudoDropDownContainer: {
    alignItems: 'center',
    boxSizing: 'border-box',
    display: 'flex',
    height: 36,
    padding: theme.spacing(0, 1),
    cursor: 'pointer',
    borderRadius: 6,
    marginLeft: '-8px',
    fontSize: 13,
    '&:hover': {
      backgroundColor: 'rgba(21,27,38,.04)'
    }
  }
}))

export const DueDateField = ({ handleDateChange, formData, classes }) => {
  const { t } = useTranslation()
  return (
    <div className="flex items-center">
      <div className="flex items-center">
        <div className="text-xs font-medium mr-2 whitespace-nowrap">
          {t('SET_DUE_DATE')}
        </div>
        <div className="leading-3 ml-2">
          <DatePicker
            onChange={date => handleDateChange(date)}
            selected={formData.dueDate ? new Date(formData.dueDate) : null}
            showTimeSelect
            customInput={
              formData.dueDate ? (
                <input className="text-xs dark:bg-dark-main4 text-gray-600 dark:text-gray-400" />
              ) : (
                <CalenderIcon
                  className="text-base text-gray-600 dark:text-dark-light"
                  width="2.5em"
                />
              )
            }
            minDate={new Date()}
            placeholderText="Select Date"
            dateFormat="MMM d, yyyy h:mm aa"
            className={classes.pseudoDropDownContainer}
            // popperPlacement="top-start"
          />
        </div>
      </div>
    </div>
  )
}

const PriorityField = ({ formData, setAnchorEl }) => {
  const { t } = useTranslation()
  return (
    <div className="flex items-center">
      <div className="text-xs font-medium mr-2">
        {t('SET_REQUEST_PRIORITY')}
      </div>
      <div className="leading-3">
        {formData.priority.order && formData.priority.order !== 5 ? (
          <button onClick={e => setAnchorEl(e.currentTarget)}>
            <CustomFlag priority={formData.priority.order} />
          </button>
        ) : (
          <CustomTooltip title={t('SET_PRIORITY')} placement="top">
            <button
              className="text-base text-gray-400 w-6 h-6 border border-gray-400 border-dashed rounded-full flex justify-center items-center"
              onClick={e => setAnchorEl(e.currentTarget)}
            >
              <FlagOutlined className="text-xs 2xl:text-base" />
            </button>
          </CustomTooltip>
        )}
      </div>
    </div>
  )
}

const StatusField = ({ formData, handleOptionsBtnClick }) => {
  const { t } = useTranslation()
  return (
    <div className="flex items-center">
      <div className="text-xs font-medium mr-2">{t('STATUS')}</div>
      <div onClick={handleOptionsBtnClick}>
        <Pill variant={pillVariantObj[formData.status]}>
          {t(
            formData.status === 'resolved'
              ? 'COMPLETED'
              : formData.status?.toUpperCase()
          )}
        </Pill>
      </div>
    </div>
  )
}

const ClientSelector = ({ t, client, setClient }) => {
  const theme = useTheme()
  const userClients = useSelector(state => state.userClients.data)

  return (
    <div className="mb-3">
      <FormLabel required>{t('SELECT_A_CLIENT')}</FormLabel>
      <Select
        styles={reactSelectCustomStyles(theme)}
        options={userClients.filter(user => user.isActive)}
        getOptionLabel={option => option.name}
        getOptionValue={option => option._id}
        value={client}
        placeholder={t('SELECT_A_CLIENT')}
        onChange={setClient}
      />
    </div>
  )
}

export default RaiseTicketModal
